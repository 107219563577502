//Identificando los elementos del login

const claveElemento = document.querySelector("#clave");
const verclaveElemento = document.querySelector("#inputverclave");
const formularioElemento = document.querySelector("#formulario");

let estadoclave = false;

let aux = false;

//Ver cambios en el checkbox
verclaveElemento.addEventListener("change", (e)=>{
    estadoclave = !estadoclave;
    if(estadoclave){
        claveElemento.type = "text";
    }else{
        claveElemento.type = "password";
    }
    
});

//Evento encargado de crear la ventana modal antes de iniciar sesion
formularioElemento.addEventListener("submit", (e)=>{
    e.preventDefault();

    if(!aux){
        //Creando la ventana modal
        const layer = document.createElement("DIV");
        layer.classList.add("layer");
        const ventanaModal = document.createElement("DIV");
        ventanaModal.innerHTML = 
        `
        <h2>IMPORTANTE</h2>
        <a class="importante" target="_blank" href="https://www.sonora.gob.mx/gobierno/politicas-de-uso-y-privacidad">Politica de Privacidad</a>
        <p>La sesión se cerrará en un lapso de 10 minutos de inactividad para salvaguardar su seguridad</p>
        <a id='boton_iniciarsesion' href='#' class='boton-rojo-block'>Continuar</a>
        `;
        ventanaModal.classList.add("ventanaModal");
        layer.appendChild(ventanaModal);
        document.body.appendChild(layer);
        const btniniciarsesion = document.querySelector("#boton_iniciarsesion");
        btniniciarsesion.addEventListener("click", ()=>{
            formularioElemento.submit();
        })
    }

    aux = true;
});